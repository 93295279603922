<template>
  <div class="home">
    <div class="bg">
      <div class="button-list">
        <img src="../assets/images/dszswd.png" alt="党史知识问答" class="button-item" @click="goAnswer(0)">
        <img src="../assets/images/czzswd.png" alt="长征知识问答" class="button-item" @click="goAnswer(1)">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  methods: {
    goAnswer(type) {
      this.$router.push({
        path: '/answer',
        query: {
          type: type
        }
      })
    }
  }
}
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/bg.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-list {
  display: flex;
  justify-content: center;
}

.button-item {
  width: 40%;
  margin-right: 5%;
  cursor: pointer;
}

.button-item:last-child {
  margin-right: 0;
}
</style>
